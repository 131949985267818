import React, { useEffect, useRef } from "react"
import { useLocation } from "@reach/router"
import Countdown from "react-countdown"
import {
  getActionByPage,
  getActionByType,
  getCodes,
  getCodeFromType,
  getPageCodes,
} from "../../../../helpers/helper.actions"
import { connect } from "react-redux"
import OwlCarousel from "react-owl-carousel3"
import { informCustomEvent } from "../../../../helpers/helper.analytics"
import { useGetPushQuery } from "../../../../redux/mediacore/push"

const BannerTOP = ({ page, identifier = "" }) => {
  const location = useLocation()
  const slider = useRef()

  const { data: actions, isLoading: loading } = useGetPushQuery(6)

  const slider_action = getActionByPage(
    getActionByType(actions, getCodeFromType("Slider de Imagenes Encabezado")),
    getPageCodes(page)
  )
  const image_action = getActionByPage(
    getActionByType(actions, getCodeFromType("Imagen Encabezado")),
    getPageCodes(page)
  )
  const video_action = getActionByPage(
    getActionByType(actions, getCodeFromType("Video Encabezado")),
    getPageCodes(page)
  )

  const getSize = () => (screen.width < 992 ? false : true)

  const goTo = () => {
    if (video_action[0].url_destiny) {
      informCustomEvent(
        "PUSH_" +
          getCodes(video_action[0].type, video_action[0].section) +
          "_" +
          video_action[0].name
      )
      window.open(
        video_action[0].url_destiny + video_action[0].text_utm,
        "_blank",
        "noopener,noreferrer"
      )
    }
  }

  useEffect(() => {
    if (video_action.length > 0) {
      informCustomEvent(
        "SHOW_" +
          getCodes(video_action[0]?.type, video_action[0]?.section) +
          "_" +
          video_action[0]?.name
      )
    }
    if (image_action.length > 0) {
      informCustomEvent(
        "SHOW_" +
          getCodes(image_action[0]?.type, image_action[0]?.section) +
          "_" +
          image_action[0]?.name
      )
    }
    if (slider_action.length > 0) {
      informCustomEvent(
        "SHOW_" +
          getCodes(slider_action[0]?.type, slider_action[0]?.section) +
          "_" +
          slider_action[0]?.name
      )
    }
  }, [])

  return slider_action.length > 0 ||
    video_action.length > 0 ||
    image_action.length > 0 ? ( //Caso Actions video/slider
    <>
      {slider_action.length > 0 && (
        <>
          <div
            className={`full-slider ${identifier ? identifier : ""}`}
            id="section-galery-home"
          >
            <div id="dev-secuencial">
              <div className="slider" id="dev-gallery">
                <OwlCarousel
                  items={1}
                  ref={slider}
                  margin={0}
                  nav={false}
                  dots={true}
                  loop={true}
                  rewind={true}
                  autoplay={true}
                  autoplayTimeout={slider_action[0]?.transition_time * 1000}
                  id="prop-gallery"
                >
                  {slider_action[0].files.map((file, index) => (
                    <div
                      className="item banner-main banner-ficha emprendimiento"
                      key={index}
                    >
                      <img
                        src={file.file}
                        className="object-cover object-center cursor-pointer"
                      />
                      <div className="info-banner position-absolute">
                        <div className="info row align-items-center text-center justify-content-center">
                          <div className="col-8 col-lg-12">
                            <a
                              target={"_blank"}
                              href={
                                file.url_destiny + slider_action[0].text_utm
                              }
                              onClick={() =>
                                informCustomEvent(
                                  "PUSH_" +
                                    getCodes(
                                      slider_action[0].type,
                                      slider_action[0].section
                                    ) +
                                    "_" +
                                    slider_action[0].name
                                )
                              }
                              className={
                                "link-gallery " +
                                (!file.url_destiny && "d-none")
                              }
                            ></a>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </OwlCarousel>
                <div className="arrows-owl">
                  <div className="arrow" onClick={() => slider.current.prev()}>
                    <div className="next">
                      <i className="icon-arrow-right"> </i>
                    </div>
                  </div>
                  <div className="arrow " onClick={() => slider.current.next()}>
                    <div className="next">
                      <i className="icon-arrow-right"> </i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <Searcher /> */}
          </div>
        </>
      )}
      {video_action.length > 0 ? (
        <div
          className={
            "banner-main " +
            (video_action[0].url_destiny === ""
              ? "cursor-default"
              : "cursor-pointer") +
            (identifier ? identifier : "")
          }
          onClick={() => goTo()}
        >
          {/* { getSize() &&  */}
          {
            <video loop autoPlay muted>
              <source
                src={
                  video_action[0]?.files?.filter(
                    video => video.device === "desktop"
                  )?.[0].file ?? ""
                }
                type="video/mp4"
              />
            </video>
          }
          {/* <Searcher /> */}
        </div>
      ) : null}
      {image_action.length > 0 ? (
        <div
          id="home-main"
          onClick={() =>
            image_action[0].url_destiny
              ? window.open(
                  image_action[0].url_destiny,
                  "_blank",
                  "noopener,noreferrer"
                ) +
                informCustomEvent(
                  "PUSH_" +
                    getCodes(image_action[0].type, image_action[0].section) +
                    "_" +
                    image_action[0].name
                )
              : ""
          }
          class={
            "banner-main " +
            (image_action.length === 0 || image_action[0]?.url_destiny === ""
              ? "cursor-default"
              : "") +
            (identifier ? identifier : "")
          }
          style={{
            zIndex: "9",
            backgroundImage: "url(" + (image_action[0]?.files[0]?.file + ")"),
          }}
        ></div>
      ) : null}
    </>
  ) : null
}

export default connect(
  state => ({
    actions: state.actions.actions,
  }),
  null
)(BannerTOP)
